<template>
    <div>
        <y-panel
            :title="cart.is_pre_order ? $t('cart.pre.details_panel') : $t('cart.order.details_panel')"
            :class="{'no-panel': noPanel}"
        >
            <div class="row">
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.details.customer.name') }}
                        </div>
                        <div class="value">
                            <router-link :to="routeToProfile">
                                {{ cart.customer.name_full }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.details.customer.tel') }}
                        </div>
                        <div
                            v-if="cart.customer.mobile"
                            class="value"
                        >
                            {{ cart.customer.mobile | digits }}
                        </div>
                        <div
                            v-else
                            class="value"
                        >
                            {{ `-` }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ cart.is_pre_order ? $t('cart.details.pre.create_date') : $t('cart.details.order.create_date') }}
                        </div>
                        <div
                            v-if="cart.effected_at"
                            class="value"
                        >
                            {{ cart.effected_at | date('YYYY/MM/DD', $i18n.locale) | digits }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div
                        v-if="cart.pre_expires_at"
                        class="detail-item"
                    >
                        <div class="title">
                            {{ $t('cart.details.pre.expire_date') }}
                        </div>
                        <div class="value">
                            {{ cart.pre_expires_at | date('YYYY/MM/DD', $i18n.locale) | digits }}
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="cart.shipping_details"
                class="row"
            >
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.details.shipping.delivery_date') }}
                        </div>
                        <div
                            v-if="cart.shipping_details.slot.starts_at"
                            class="value"
                        >
                            <y-date-label :element="{value: cart.shipping_details.slot.starts_at}" />
                        </div>
                        <div
                            v-else
                            class="value"
                        >
                            {{ `-` }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.details.shipping.delivery_interval') }}
                        </div>
                        <div
                            v-if="cart.shipping_details.slot && (cart.shipping_details.slot.starts_at || cart.shipping_details.slot.ends_at)"
                            class="value"
                        >
                            {{ $t(
                                'cart.details.shipping.delivery_interval_mask',
                                [
                                    formatTime(cart.shipping_details.slot.starts_at),
                                    formatTime(cart.shipping_details.slot.ends_at),
                                ])
                            }}
                        </div>
                        <div
                            v-else
                            class="value"
                        >
                            {{ `-` }}
                        </div>
                    </div>
                    <div />
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ cart.is_pre_order ? $t('cart.details.pre.status') : $t('cart.details.order.status') }}
                        </div>
                        <div class=" label rounded blue">
                            {{ cart.status_title }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.details.change_status_date') }}
                        </div>
                        <div
                            v-if="cart.status_updated_at"
                            class="value"
                        >
                            {{ cart.status_updated_at | date('YYYY/MM/DD', $i18n.locale) | digits }}
                        </div>
                        <div
                            v-else
                            class="value"
                        >
                            {{ `-` }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div
                    v-if="cart.items_count"
                    class="col-sm-6"
                >
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.count') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: cart.items_count }" />
                        </div>
                    </div>
                </div>
                <div
                    v-if="cart.tracking_number"
                    class="col-sm-6"
                >
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.tracking_number') }}
                        </div>
                        <div class="value">
                            <y-form-title
                                :element="{ text: digitsNumber(cart.tracking_number) }"
                                :copy="String(cart.tracking_number)"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="detail-item">
                <div class="title">
                    {{ $t('cart.details.customer.address') }}
                </div>
                <div
                    v-if="cart.address && (cart.address.address || cart.address.province_name || cart.address.city_name)"
                    class="value"
                >
                    {{ cart.address.province_name ? $t('cart.details.customer.province', [cart.address.province_name]) : `` }}
                    {{ cart.address.city_name ? $t('cart.details.customer.province', [cart.address.city_name]) : `` }}
                    {{ cart.address.address | digits }}
                    {{ cart.address.floor ? $t('cart.details.customer.floor', [digitsNumber(Number(cart.address.floor))]) : `` }}
                    {{ cart.address.unit_number ? $t('cart.details.customer.unit_number', [digitsNumber(Number(cart.address.unit_number))]) : `` }}
                </div>
                <div
                    v-else
                    class="value"
                >
                    {{ `-` }}
                </div>
            </div>
        </y-panel>

        <y-panel
            v-if="!cart.is_pre_order"
            :title="$t('cart.order.details_finance_panel')"
            :class="{'no-panel': noPanel}"
        >
            <div class="row">
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.status') }}
                        </div>
                        <div class="label rounded">
                            {{ cart.payment_status_title }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.currency') }}
                        </div>
                        <div class="value">
                            {{ cart.currency_title ? cart.currency_title : `-` }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.payable') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: cart.payable_amount }" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.paid') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: cart.paid_amount }" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.order_total') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: cart.total_price }" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.tax_total') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: cart.total_tax }" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.shipping') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: cart.delivery_price }" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.wares_discount') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: cart.items_discount }" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.overall_discount') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: cart.overall_discount }" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.coupons_discount') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: cart.coupons_discount }" />
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="cart.coupons && cart.coupons.length"
                class="table-wrapper"
            >
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                {{ $t('cart.table.payment.coupons.code') }}
                            </th>
                            <th>
                                {{ $t('cart.table.payment.coupons.discount') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="code in cart.coupons"
                            :key="code.id"
                        >
                            <td>
                                {{ code.code_slug }}
                            </td>
                            <td>
                                <y-form-amount
                                    class="mb0"
                                    :element="{ value: code.applied_discount }"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </y-panel>
    </div>
</template>

<script>

    import { digits } from '@nodes/helpers/number';

    import YFormTitle from '@deps/form/elements/Title';
    import YFormAmount from '@deps/form/elements/Amount';
    import YDateLabel from '@deps/form/elements/DateLabel';

    import { jDate } from '@helpers/date';

    export default {
        name: 'CartDetailsPanel',

        components: {
            YFormTitle,
            YFormAmount,
            YDateLabel,
        },

        props: {
            cart   : Object,
            noPanel: {
                type   : Boolean,
                default: false,
            },
        },

        computed: {
            /**
             * Route to Profile
             */
            routeToProfile() {
                return {
                    name  : 'persons-users-profile',
                    params: {
                        id: this.cart.customer.id,
                    },
                };
            },
        },

        methods: {
            /**
             * Convert number to desire locale
             *
             * @param value
             */
            digitsNumber(value) {
                return digits(value, this.$i18n.locale);
            },

            /**
             * Format date to human-readable time string
             *
             * @param {number} date
             */
            formatTime(date) {
                return date
                    ? jDate(date, 'HH:mm')
                    : '-';
            },
        },
    };
</script>
